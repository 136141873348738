import React from 'react'
import SideBar from './SideBar'
import { graphql, StaticQuery } from 'gatsby'

class Layout extends React.Component {
  render() {
    const { children } = this.props
    return (
      <StaticQuery
        query={blogNameQuery}
        render={data => (
          <div>
            <SideBar blogTitle={data.wordpressPage.title} />
            <div className="content-wrapper">{children}</div>
          </div>
        )}
      />
    )
  }
}

export default Layout

const blogNameQuery = graphql`
  query name {
    wordpressPage(slug: { eq: "home" }) {
      title
    }
  }
`
