import React from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebook,
  faTwitter,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

function SideBar({blogTitle, blogSubtitle}) {
  return (
    <div className="sidebar-wrapper">
      <div className="header">
        <Link to="/" className="site-name">
          {blogTitle}
        </Link>
        <div className="grey-rule w-hidden-small w-hidden-tiny" />
        <p className="site-description">
          This is example seo blog with number 02. Can be used for lying to the various search engines
        </p>
        <div className="grey-rule w-hidden-small w-hidden-tiny" />
        <nav className="navigation">
          <Link className="nav-link py-0" activeClassName="w--current" to="/">
            home
          </Link>
          <Link activeClassName="w--current" className="nav-link py-0" to="/about">
            about
          </Link>
          <Link activeClassName="w--current" className="nav-link py-0" to="/contact">
            contact
          </Link>
          <div className="grey-rule w-hidden-small w-hidden-tiny" />
        </nav>
        <div className="social-link-group">
          <FontAwesomeIcon
            icon={faFacebook}
            className="social-icon-link w-inline-block"
          />
          <FontAwesomeIcon
            icon={faTwitter}
            className="social-icon-link w-inline-block"
          />
          <FontAwesomeIcon
            icon={faLinkedin}
            className="social-icon-link w-inline-block"
          />
          <FontAwesomeIcon
            icon={faEnvelope}
            className="social-icon-link w-inline-block"
          />
        </div>
        <p className="built-with-webflow w-hidden-small w-hidden-tiny">
          Built with{' '}
          <a className="webflow-link" href="https://www.gatsbyjs.org">Gatsby</a>
        </p>
      </div>
    </div>
  )
}

export default SideBar
